import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import {
  API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SERVER_ID,
  APP_ID,
} from './config'

const config = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SERVER_ID,
  appId: APP_ID,
}
firebase.initializeApp(config)
