/** @jsx jsx */
import { Fragment, useState, useRef, useCallback } from 'react'
import { jsx } from '@emotion/core'
import { Formik, FormikActions } from 'formik'
import { assoc, split } from 'ramda'
import { Card, Flex, Input, Button, Spinner, useMeasure } from 'vr-horizon-ui'
import { useSpring, animated } from 'react-spring'
import * as firebase from 'firebase/app'
import * as yup from 'yup'
import { API_URL, DEFAULT_REDIRECT } from '../config'
import { useNavigation } from 'react-navi'
import { parse, stringify } from 'querystring'

const Form = Flex.withComponent('form')
const AnimatedFlex = Flex.withComponent(animated.div)

type Props = {}
type FormValues = {
  email: string
  password: string
  confirmPassword: string
}

const redirectToUrlWithToken = (url: string, token: string) => {
  const [urlWithoutSearch, searchString] = split('?', url)
  const search = parse(searchString)
  window.location.href = `${urlWithoutSearch}?${stringify(
    assoc('token', token, search),
  )}`
}
const loginCall = async ({ email, password }: FormValues) => {
  const result = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
  if (!result.user) throw new Error('Login unsuccessful')
  const idToken = await result.user.getIdToken()
  await fetch(`${API_URL}/auth/signIn`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ idToken }),
    headers: { 'Content-Type': 'application/json' },
  })
  const response = await fetch(`${API_URL}/auth/authStatus`, {
    credentials: 'include',
  })
  const { token } = await response.json()
  return token
}

const validation = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(6)
    .required(),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null]),
})
export const LoginForm = (props: Props) => {
  const [isRegistering, setRegistering] = useState(false)
  const input = useRef<null | HTMLDivElement>(null)
  const measure = useMeasure(input)
  const spring = useSpring({
    height: measure && isRegistering ? measure.height : 0,
  })

  const navigation = useNavigation()
  const login = useCallback(
    async (values: FormValues, form: FormikActions<FormValues>) => {
      try {
        const token = await loginCall(values)
        const { redirect } = navigation.getCurrentValue().url.query
        if (redirect || DEFAULT_REDIRECT)
          redirectToUrlWithToken(redirect || DEFAULT_REDIRECT, token)
        else navigation.navigate('/my-account')
      } catch (e) {
        console.log('Error logging in:', e)
      }
      form.setSubmitting(false)
    },
    [navigation],
  )
  const register = useCallback(
    async ({ email, password }: FormValues) => {
      const credential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
      if (credential.user) {
        await firebase
          .firestore()
          .collection('users')
          .doc(credential.user.uid)
          .set({ email, admin: false, confirmed: false })
        navigation.navigate('/registration-complete')
      }
    },
    [navigation],
  )

  return (
    <Formik
      validationSchema={validation}
      initialValues={{ email: '', password: '', confirmPassword: '' }}
      onSubmit={isRegistering ? register : login}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Card bg="white" maxWidth={464} width="90vw" border py={3}>
          <Form onSubmit={handleSubmit} flexDirection="column" flex={1}>
            <Input
              px={3}
              label="E-mail address"
              name="email"
              type="email-address"
              placeholder="E-mail address"
              error={touched.email && !!errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              px={3}
              label="Password"
              name="password"
              type="password"
              placeholder="Password"
              error={touched.password && !!errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <AnimatedFlex
              style={spring}
              css={{ overflow: 'hidden' }}
              flexDirection="column">
              <Flex px={3} flexDirection="column" ref={input}>
                <Input
                  pb={1}
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  error={touched.confirmPassword && !!errors.confirmPassword}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Flex>
            </AnimatedFlex>
            <Flex mt={3} justifyContent="center">
              {isSubmitting ? (
                <Spinner color="primary" />
              ) : (
                <Fragment>
                  <Button
                    key={!isRegistering ? 'submit' : 'button'}
                    type={!isRegistering ? 'submit' : 'button'}
                    onClick={e => {
                      setRegistering(false)
                    }}
                    color={!isRegistering ? 'white' : 'primary'}
                    bg={!isRegistering ? 'primary' : 'lights.4'}>
                    Log In
                  </Button>
                  <Button
                    key={isRegistering ? 'submit' : 'button'}
                    type={isRegistering ? 'submit' : 'button'}
                    onClick={e => {
                      setRegistering(true)
                    }}
                    ml={2}
                    color={isRegistering ? 'white' : 'primary'}
                    bg={isRegistering ? 'primary' : 'lights.4'}>
                    Register
                  </Button>
                </Fragment>
              )}
            </Flex>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
