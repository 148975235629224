/** @jsx jsx */
import { useRef, ComponentType, ComponentProps } from 'react'
import { jsx } from '@emotion/core'
import { useMeasure, Flex, Heading } from 'vr-horizon-ui'
import { animated, useSpring, AnimatedValue } from 'react-spring'
import { LoginForm } from './LoginForm'
import { Gradient } from '../Components/Gradient'
import { Logo } from '../Components/Logo'

const AnimatedFlex: ComponentType<
  ComponentProps<typeof Flex> & ComponentProps<typeof animated.div>
> = Flex.withComponent(animated.div)
const AnimatedLogo = Logo.withComponent(animated.div)

export const Login = () => {
  const cardRef = useRef<null | HTMLDivElement>(null)
  const measure = useMeasure(cardRef)

  const { logo, ...card }: AnimatedValue<any> = useSpring({
    from: {
      logo: 0,
      height: 0,
      opacity: 0,
    },
    to: async (next: (styles: {}) => Promise<void>) => {
      await new Promise(resolve => setTimeout(resolve, 500))
      if (!measure) return
      await next({ logo: 1 })
      await next({
        height: measure.height,
        opacity: 1,
      })
      await next({ height: 'auto' })
    },
  })
  return (
    <Gradient
      flex={1}
      justifyContent="center"
      alignItems="center"
      px={3}
      flexDirection="column">
      <AnimatedFlex style={{ opacity: logo }} flexDirection="column">
        <Flex mb={3} justifyContent="center">
          <AnimatedLogo />
        </Flex>
        <Heading mb={3} color="white">
          Login to VR Horizon
        </Heading>
      </AnimatedFlex>
      <AnimatedFlex
        flexDirection="column"
        alignItems="center"
        style={{
          overflow: 'hidden',
          transformOrigin: 'top',
          ...card,
        }}>
        <Flex
          flexDirection="column"
          flexShrink={0}
          ref={cardRef}
          css={{ overflow: 'hidden' }}>
          <LoginForm />
        </Flex>
      </AnimatedFlex>
    </Gradient>
  )
}
