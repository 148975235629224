import React from 'react'
import { View, Router } from 'react-navi'
import { mount, route } from 'navi'
import { Login } from './Login'
import './firebase'
import { MyAccount } from './MyAccount'
import { RegisterComplete } from './RegisterComplete'

const routes = mount({
  '/': route({
    title: 'VR-Horizon | Log In',
    view: <Login />,
  }),
  '/registration-complete': route({
    title: 'VR-Horizon | Register Complete',
    view: <RegisterComplete />,
  }),
  '/my-account': route({
    title: 'VR-Horizon | My Account',
    view: <MyAccount />,
  }),
})

const App: React.FC = () => {
  return (
    <Router routes={routes}>
      <View />
    </Router>
  )
}

export default App
