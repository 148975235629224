import { compose, map, toPairs, fromPairs } from 'ramda'
type Env = {
  API_URL: string
  API_KEY: string
  AUTH_DOMAIN: string
  DATABASE_URL: string
  PROJECT_ID: string
  STORAGE_BUCKET: string
  MESSAGING_SERVER_ID: string
  APP_ID: string
  DEFAULT_REDIRECT: string
}

export const {
  API_URL,
  API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SERVER_ID,
  APP_ID,
  DEFAULT_REDIRECT,
}: Env = compose(
  fromPairs,
  map(([key, val]) => [key.replace('REACT_APP_', ''), val]) as any,
  toPairs,
)(process.env || {}) as any
