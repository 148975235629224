/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex, Card, Table } from 'vr-horizon-ui'
import { Gradient } from '../Components/Gradient'
import { Logo } from '../Components/Logo'

// const fetchUser = async () => {
//   return null
// }

type Props = {}
export const MyAccount = (props: Props) => {
  // const [user, setUser] = useState(null)
  // useEffect(() => {
  //   fetchUser().then(setUser)
  // }, [])

  return (
    <Flex bg="lights.3" flex={1}>
      <Card bg="primaries.1" shadow>
        <Gradient width={120} flexDirection="column" alignItems="center" py={3}>
          <Logo size={64} />
        </Gradient>
      </Card>
      <Flex flex={1} flexDirection="column">
        <Table></Table>
      </Flex>
    </Flex>
  )
}
