import React from 'react'
import { Gradient } from './Components/Gradient'
import { Card, Heading, Text, Flex } from 'vr-horizon-ui'
import { Logo } from './Components/Logo'

type Props = {}
export const RegisterComplete = (props: Props) => {
  return (
    <Gradient
      flex={1}
      justifyContent="center"
      alignItems="center"
      px={3}
      flexDirection="column">
      <Flex mb={3}>
        <Logo />
      </Flex>
      <Card
        bg="white"
        maxWidth={464}
        width="90vw"
        border
        p={3}
        flexDirection="column">
        <Heading mb={2}>Registration successful</Heading>
        <Text>
          Please wait until we confirm your account. You will be able to log in
          shortly!
        </Text>
      </Card>
    </Gradient>
  )
}
